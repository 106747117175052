<template>
  <div class="col-12 col-lg-8 c g">
    <div class="card card-body">
        <h5 class="g">
            الرصيد الحاليك {{ data.current }} ريال
        </h5>
        <div class="col-12 table-responsive">
            <table class="table table-bordered table-hover">
                <thead>
                    <th>
                        الشركة
                    </th>
                    <th>
                        المبلغ
                    </th>
                    <th>
                        عمولتك
                    </th>
                    <th>
                        الباقي
                    </th>
                    <th>
                        تمت محاسبة الشركة
                    </th>
                </thead>
                <tbody>
                    <tr v-for="c in data.companies" :key="c.company_id">
                        <td>{{ c.title }}<br>
                            <a :href="'tel:' + c.phone">
                                <small class="text-success">
                                <i class="fa fa-phone"></i>
                                {{ c.phone }}
                            </small>
                            </a>
                        </td>
                        <td>{{ c.total }} ريال</td>
                        <td>{{ c.tips }} ريال <span class="text-muted">({{ c.tips_count }} حجز)</span></td>
                        <td>
                            {{ c.all - (c.total + c.tips) }} ريال
                        </td>
                        <td>
                            <button v-if="!c.done" class="btn btn-sm btn-success" @click="done(c)">
                                <i class="fa fa-check"></i>
                                تمت المحاسبة
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            الاجمالي
                        </td>
                        <td>
                            {{ data.total }} ريال
                        </td>
                        <td>
                            {{ data.all_tips }} ريال
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            jwt: JSON.parse(localStorage.getItem("user")).jwt,
            data: {
                companies: []
            }
        }
    },
    created(){
        var g = this;
        g.loading = true;
        $.post(api + '/cash/orders/stats', {
            jwt: this.jwt,
        }).then(function(r){
            g.loading = false;
            if(r.status != 100){
                alert(r.response)
            }else{
                g.data = r.response
            }
        }).fail(function(){
            g.loading = false;
            alert("حدث مشكلة في الاتصال")
        })
    },
    methods: {
        done(c){
            var g = this;
            if(confirm("متاكد من هذا الاجراء؟")){
                var notes = prompt("هل لديك ملاحظات؟", "")
                $.post(api + '/cash/orders/stats-done', {
                    jwt: this.jwt,
                    ids: c.ids,
                    notes: notes
                }).then(function(r){
                    g.loading = false;
                    if(r.status != 100){
                        alert(r.response)
                    }else{
                        location.reload()
                    }
                }).fail(function(){
                    g.loading = false;
                    alert("حدث مشكلة في الاتصال")
                }) 
            }
        }
    }
}
</script>

<style>

</style>